<template>
  <div class="flex flex-col">
    <div
      class="flex flex-row items-center cursor-pointer flex-nowrap"
      @click="toggleDeliverScope"
    >
      <slot name="label" />
      <UtilArrowUpDown class="ml-sm showMoreLessArrow" :down="!showMore" />
    </div>
    <div :class="{ hidden: !showMore }">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { UtilArrowUpDown } from '@/complib';
defineProps({
  withArrows: {
    type: Boolean,
    default: true,
  },
});
const showMore = ref(false);
function toggleDeliverScope() {
  showMore.value = !showMore.value;
}
</script>
