<template>
  <div class="px-sm md:px-0">
    <h5 class="text-sm">
      {{ t('checkout.review.payment.sepa.consent.title') }}
    </h5>
    <div class="table text-sm pt-sm">
      <div class="table-row">
        <div class="table-cell pr-xs">
          {{ t('checkout.review.payment.sepa.creditor.label') }}:
        </div>
        <div class="table-cell">
          {{ t('checkout.review.payment.sepa.creditor.value') }}
        </div>
      </div>
      <div class="table-row">
        <div class="table-cell pr-xs">
          {{ t('checkout.review.payment.sepa.creditor.id.label') }}:
        </div>
        <div class="table-cell">
          {{ t('checkout.review.payment.sepa.creditor.id.value') }}
        </div>
      </div>
    </div>
    <p class="text-sm pt-sm leading-xs">
      {{ t('checkout.review.payment.sepa.consent.text') }}
    </p>
    <div class="flex font-roboto shrink-0 pt-sm">
      <Icon
        icon-class="fad fa-circle-info"
        style="
          --fa-primary-color: var(--thm-text-base);
          --fa-secondary-color: var(--thm-status-warn-base);
          --fa-secondary-opacity: 1;
        "
      />
      <p class="text-sm leading-xs pl-[10px] text-grey-dark">
        {{ t('checkout.review.payment.sepa.hint') }}
      </p>
    </div>
  </div>
</template>
<script setup lang="ts">
import { Icon } from '@/complib';
const { t } = useTrans();
</script>
