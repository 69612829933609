<template>
  <div>
    <NotificationBar
      v-if="bankData.error.includes('BANKDATA_INVALID')"
      :type="NotificationType.ERROR"
      class="mb-md"
    >
      <template #default>
        <div class="self-center py-xs">
          {{ t('bankDataInput.error.general') }}
        </div>
      </template>
    </NotificationBar>
    <FormKit
      :id="id"
      type="form"
      class="max-w-md"
      :class="{
        'opacity-50 cursor-wait pointer-events-none':
          disabled || showDataFromAccount,
      }"
      :disabled="disabled || showDataFromAccount"
      :actions="false"
      :incomplete-message="false"
      spellcheck="false"
      :config="{
        validationVisibility: bankData.error.includes('BANKDATA_INVALID')
          ? 'live'
          : 'blur',
      }"
    >
      <template #default>
        <TextInput
          id="bankName"
          v-model="bankData.bankName"
          :disabled="disabled || showDataFromAccount"
          :name="t('bankDataInput.bankName')"
          :required-message="t('bankDataInput.bankName.required')"
          :label="t('bankDataInput.bankName')"
          :placeholder="t('bankDataInput.bankName')"
          :required="true"
          :validation="validationRules"
          :validation-visibility="
            bankData.error.includes('BANKDATA_INVALID') ? 'live' : 'blur'
          "
          :autofocus="autofocus"
          @update:model-value="checkValidityDebounced"
        ></TextInput>
        <TextInput
          id="iban"
          v-model="bankData.iban"
          :disabled="disabled || showDataFromAccount"
          name="IBAN"
          :required-message="t('bankDataInput.iban.required')"
          :label="t('bankDataInput.iban')"
          :placeholder="t('bankDataInput.iban')"
          :required="true"
          :validation="validationRules"
          :validation-visibility="
            bankData.error.includes('BANKDATA_INVALID') ? 'live' : 'blur'
          "
          @update:model-value="checkValidityDebounced"
        ></TextInput>
        <TextInput
          id="bic"
          v-model="bankData.bic"
          :disabled="disabled || showDataFromAccount"
          name="BIC"
          :required-message="t('bankDataInput.bic.required')"
          :label="t('bankDataInput.bic')"
          :placeholder="t('bankDataInput.bic')"
          :required="true"
          :validation="validationRules"
          :validation-visibility="
            bankData.error.includes('BANKDATA_INVALID') ? 'live' : 'blur'
          "
          @update:model-value="checkValidityDebounced"
        ></TextInput>
        <UtilShowLessMore>
          <template #label>
            <div class="text-primary-base">
              {{ t('bankDataInput.hint.showLegalHint') }}
            </div>
          </template>
          <template #content>
            <SepaHint class="mt-xs"></SepaHint>
          </template>
        </UtilShowLessMore>
        <CollectiveInvoiceSelect
          v-if="$siteIdent.startsWith('minilu') && !hideCollectiveInvoiceSelect"
          class="pt-md mb-sm"
          :model-value="bankData.collectiveInvoice"
          :disabled-options="disabledOptions"
          @set-collective-invoice="setCollectiveInvoice"
        />
      </template>
    </FormKit>
  </div>
</template>
<script setup lang="ts">
import {
  TextInput,
  NotificationBar,
  NotificationType,
  UtilShowLessMore,
} from '@/complib';
import { FormKit } from '@formkit/vue';
import type { BankData } from './types';
import useValidateBankData from '@/composables/payment/useValidateBankData';
import { useUserContext } from '~/stores/useUserContext';
import { UseStateKeys } from '~/useStateKeys';
import SepaHint from '@/components/shop/checkout/payment/components/sepa-hint.vue';
import CollectiveInvoiceSelect from './collectiveInvoiceSelect.vue';

const props = defineProps({
  /**Keeps the input data until you leave checkout-pages for example
   * Does not work with modelValue!
   */
  keepData: {
    type: String as PropType<'NEVER' | 'CHECKOUT'>,
    default: 'NEVER',
  },
  /**Shows Masked Bankdata from account - not editable. */
  showDataFromAccount: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: Object as PropType<BankData>,
    default: null,
  },
  disabledOptions: {
    type: Boolean,
    default: false,
  },
  hideCollectiveInvoiceSelect: {
    type: Boolean,
    default: false,
  },
  autofocus: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const { t } = useTrans();
const id = 'bankDataInput';

const value = ref<BankData>(props.modelValue);
const bankData = getBankData();

const emit = defineEmits<{
  (e: 'bankDataChange', value: { isValid: boolean }): void;
  (e: 'update:modelValue', value: BankData): void;
}>();

const checkValidityDebounced = useDebounceFn(async () => {
  checkValidity();
  emit('bankDataChange', { isValid: bankData.value.error.length === 0 });
}, 200);

const validationRules = computed(() => {
  if (bankData.value.error.includes('BANKDATA_INVALID')) {
    return 'length,9999';
  } else {
    return null;
  }
});

function formatIbanView(str: string) {
  return str
    .replace(/[^\dA-Z]/g, '')
    .replace(/(.{4})/g, '$1 ')
    .trim();
}

function checkValidity() {
  if (props.showDataFromAccount) {
    bankData.value.error = [];
    return;
  }
  bankData.value.iban = formatIbanView(
    bankData.value.iban.trim().toUpperCase(),
  );

  bankData.value.bic = bankData.value.bic.trim();

  const bankDataValidation = useValidateBankData(
    bankData.value.bankName,
    bankData.value.iban,
    bankData.value.bic,
  );

  if (
    bankData.value.bankName === '' ||
    bankData.value.iban === '' ||
    bankData.value.bic === ''
  ) {
    bankData.value.error.push('BANKDATA_INCOMPLETE');
  } else if (!bankDataValidation.allOK) {
    bankData.value.error.push('BANKDATA_INVALID');
  } else {
    bankData.value.error = [];
  }
}
if (props.modelValue) {
  watch(
    () => bankData.value,
    (newValue) => {
      value.value = newValue;
    },
    { deep: true },
  );
  watch(
    value,
    (newValue) => {
      emit('update:modelValue', newValue);
    },
    { deep: true },
  );
}

function getBankData() {
  if (props.showDataFromAccount) {
    const bankData = useUserContext().maskedBankData;
    return ref({
      bankName: bankData.bankName,
      iban: bankData.iban,
      bic: bankData.bic,
      collectiveInvoice: bankData.collectiveInvoice,
      error: [],
    });
  } else {
    if (props.modelValue) {
      return ref(props.modelValue);
    } else {
      return props.keepData === 'CHECKOUT'
        ? useState<BankData>(UseStateKeys.BANKDATA_INPUT_CHECKOUT, () => {
            return {
              bankName: '',
              iban: '',
              bic: '',
              error: ['BANKDATA_INCOMPLETE'],
              collectiveInvoice: false,
            };
          })
        : ref<BankData>({
            bankName: '',
            iban: '',
            bic: '',
            error: ['BANKDATA_INCOMPLETE'],
            collectiveInvoice: false,
          });
    }
  }
}

function setCollectiveInvoice(val: boolean) {
  bankData.value.collectiveInvoice = val;
}
</script>
<style lang="postcss" scoped>
.checkbox :deep(.checkbox *) {
  @apply cursor-pointer;
}
</style>
